<template>
  <div class="dev-group">
    <h2 class="group-title">Grid</h2>

    <b-row v-for="row in gridRows" :key="row.key" class="dev-grid">
      <b-col v-for="(col, cIndex) in row.columns" :key="cIndex" :cols="col">
        <div ref="col">
          <div class="class"><span>{{ `col-${col}` }}</span></div>
          <div class="width"><span></span></div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DevGrid',
  data () {
    return {
      scss: this.$root.scss
    }
  },
  computed: {
    gridRows () {
      const totalColumns = this.scss.grid.columns.value
      const gridRows = Array.from({ length: totalColumns }, (arr, i) => i + 1).reverse()

      return gridRows
        .reduce((rows, r) => {
          const key = `row-${r}`
          const fillRest = totalColumns % r !== 0
          const rest = totalColumns - r
          const same = Array.from({ length: totalColumns / r - 1 }, arr => r)

          rows[key] = {
            key,
            columns: [r].concat(fillRest ? rest : same)
          }

          return rows
        }, {})
    }
  },
  methods: {
    gridSetPixelWidth () {
      this.$refs.col
        .forEach(col => {
          const pixel = col.querySelector('.width > span')
          pixel.innerHTML = `${col.scrollWidth}px`
        })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.gridSetPixelWidth()
      window.addEventListener('resize', this.gridSetPixelWidth)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.gridSetPixelWidth)
  }
}
</script>

<style lang="scss">
.dev-grid {
  align-items: stretch;

  .col,
  [class*="col-"] {
    min-height: $spacer * 3;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      height: 100%;
      background-color: $gray-200;
      text-align: center;

      .class,
      .width {
        width: 100%;
        font-family: $font-family-monospace;
        font-size: $font-size-sm * 0.75;
        color: $gray-600;

        > span {
          font-size: $font-size-sm;
          color: $body-color;
        }
      }
    }
  }

  + .dev-grid {
    margin-top: $spacer;
  }
}
</style>
